export default {
  agencyTypeEnum () {
    return [{
      code: 'CLUB',
      name: '俱乐部'
    }, {
      code: 'ASSOCIATION',
      name: '协会'
    }, {
      code: 'SCHOOL',
      name: '学校'
    }, {
      code: 'ENTERPRISE',
      name: '企业'
    }, {
      code: 'INSTITUTION',
      name: '事业单位'
    }, {
      code: 'GOVERNMENT',
      name: '政府机关'
    }]
  },
  staffTypeEnum () {
    return [{
      code: 'COACHMAIN',
      name: '主教练',
      required: true,
      num: -1
    }, {
      code: 'COACHVICE',
      name: '副教练',
      required: false,
      num: -1
    }, {
      code: 'LEADER',
      name: '领队',
      required: false,
      num: -1
    }, {
      code: 'MANAGER',
      name: '经理',
      required: false,
      num: -1
    }, {
      code: 'OFFICIAL',
      name: '官员',
      required: false,
      num: -1
    }, {
      code: 'DOCTOR',
      name: '队医',
      required: false,
      num: -1
    }, {
      code: 'CAPTAIN',
      name: '队长',
      required: false,
      num: -1
    }, {
      code: 'REFEREE',
      name: '裁判',
      required: false,
      num: -1
    }]
  },
  agencyTypeByCode (code) {
    let name = ''
    this.agencyTypeEnum().forEach(element => {
      if (code === element.code) {
       name = element.name
      }
    })
    return name
  },
  move (e, data, width, height) {
    e.preventDefault()
    var odiv = e.target // 获取目标元素
    // 算出鼠标相对元素的位置
    var disX = e.clientX - odiv.offsetLeft
    var disY = e.clientY - odiv.offsetTop
    document.onmousemove = e => {
      // 鼠标按下并移动的事件
      // 用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
      var left = e.clientX - disX
      var top = e.clientY - disY
      if (top < 0) {
        top = 0
      }
      if (top > 370 - height) {
        top = 370 - height
      }
      if (left < 0) {
        left = 0
      }
      if (left > 250 - width) {
        left = 250 - width
      }
      // 绑定元素位置到positionX和positionY上面
      data.top = top
      data.left = left

      // 移动当前元素
      odiv.style.left = left + 'px'
      odiv.style.top = top + 'px'
    }
    document.onmouseup = e => {
      document.onmousemove = null
      document.onmouseup = null
    }
  },
  moveCertificate (e, data, templateModel) {
    e.preventDefault()
    var odiv = e.target // 获取目标元素
    // 算出鼠标相对元素的位置
    var disX = e.clientX - odiv.offsetLeft
    var disY = e.clientY - odiv.offsetTop
    document.onmousemove = e => {
      // 鼠标按下并移动的事件
      // 用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
      var left = e.clientX - disX
      var top = e.clientY - disY
      if (top < 0) {
        top = 0
      }
      if (left < 0) {
        left = 0
      }
      if (templateModel === 1) {
        if (top > 825) {
          top = 825
        }
        if (left > 580) {
          left = 580
        }
      } else {
        if (top > 580) {
          top = 580
        }
        if (left > 825) {
          left = 825
        }
      }
      // 绑定元素位置到positionX和positionY上面
      data.top = top
      data.left = left

      // 移动当前元素
      odiv.style.left = left + 'px'
      odiv.style.top = top + 'px'
    }
    document.onmouseup = e => {
      document.onmousemove = null
      document.onmouseup = null
    }
  },
  authBtnUrl (dataTree, auth, data) {
    dataTree.forEach(item => {
      if (item.perms === auth) {
        if (item.children) {
            data.status = 0,
            data.path = item.children[0].path
        }
      } else {
        if (item.children) {
          return this.authBtnUrl(item.children, auth, data)
        }
      }
    })
    return data
  },
  authBtnList (dataTree, data) {
    dataTree.forEach(item => {
      if (data.matchType === '') {
        switch (item.perms) {
          case 'B0101:{}':
            data.matchType = 'ALONE'
            break
          case 'B0102:{}':
            data.matchType = 'SERIES'
            break 
          case 'B0103:{}':
            data.matchType = 'SPORTS'
            break
          case 'B0104:{}':
            data.matchType = 'LEAGUE'
            break
        }
      }
      if (item.perms === 'B0101:{}' && data.matchType === '') {
        data.matchType = 'ALONE'
      }
      if (item.perms === 'B0109:{}' || item.perms === 'B0205') {
        if (item.children) {
          data.setup = item.children
        }
      } else if (item.perms === 'B0110:{}' || item.perms === 'B0204') {
        if (item.children) {
          data.manage = item.children
        }
      } else if (item.perms === 'B0111:{}' || item.perms === 'B0206') {
        if (item.children) {
          data.execute = item.children
        }
      } else {
        if (item.children) {
          return this.authBtnList(item.children, data)
        }
      }
    })
    return data
  },
  authTreeData (dataTree) {
    let childData = []
    dataTree.forEach(item => {
      let child = {
        title: item.menuName,
        key: item.menuId,
      }
      if (item.children) {
        child.children = this.authTreeData(item.children)
      }
      childData.push(child)
    })
    return childData
  },
  matchTreeData (dataTree, match) { 
    let childData = []
    dataTree.forEach(item => {
      let child = {
        title: item.menuName,
        data: {
          menuName: item.menuName,
          perms: item.perms,
          path: item.path,
          visible: item.visible
        },
        key: item.menuId
      }
      if (item.children) {
        child.children = this.matchTreeData(item.children, match)
      }
      // 视频审核 称重 抽签 编排
      if (child.data.perms === 'B020602' || child.data.perms === 'B020603' || child.data.perms === 'B020604') {
        // 如果是线上比赛，报名录入分数，添加视频审核权限
        // if (child.data.perms === 'B0208' && match.grade === 'INPUT') {
        //   childData.push(child)
        // }
        // 如果是重竞技项目，添加称重权限
        if (child.data.perms === 'B020602' && match.joinChannel === 'heave' && match.mode === 'OFFLINE') {
          childData.push(child)
        }
        // 如果是裁判打分，添加抽签编排权限
        if ((child.data.perms === 'B020603' || child.data.perms === 'B020604') && match.grade === 'REFEREE') {
          childData.push(child)
        }
      } else {
        childData.push(child)
      }
    })
    return childData
  },
  authTreeKey (dataTree, keyData) {
    dataTree.forEach(item => {
      if (!item.children) {
        keyData.push(item.menuId)
      }
      if (item.children) {
        this.authTreeKey(item.children, keyData)
      }
    })
    return keyData
  },
  randomNumFile (name, url) {
    var randomNum = Math.floor(Math.random() * 100000)
    var file = {
      uid: randomNum + '',
      name: name,
      status: 'done',
      url: url,
      response: { data: {url: url} }
    }
    return file
  },
  handleDate (value) {
    var date
    if (value instanceof Date) {
      date = value
    }else if (value instanceof Object) {
      date = value._d
    } else {
      var arr = value.split(':')
      if (value.indexOf(':') > -1) {
        date = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5])
      } else {
        date = new Date(arr[0], arr[1] - 1, arr[2])
      }
    }
    return date
  },
  formatDate (value, isTime) {
    var date = this.handleDate(value)
    var Y = date.getFullYear() + '-'
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
    var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate())
    if (!isTime) {
      return Y + M + D
    }
    var h =  ' ' + (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
    var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
    var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
    return Y + M + D + h + m + s
  },
  formatTime (value) {
    var date = this.handleDate(value)
    var h =  ' ' + (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
    var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
    var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
    return h + m + s
  },
  formatHm (value) {
    var date = this.handleDate(value)
    var h =  ' ' + (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
    var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
    return h + m
  },
  formatNumDate(datetime) {
    // 获取年月日时分秒值  slice(-2)过滤掉大于10日期前面的0
    var date = new Date(datetime) 
      let y = date.getFullYear()
      let MM = date.getMonth() + 1
      MM = MM < 10 ? ('0' + MM) : MM      
      let d = date.getDate()
      d = d < 10 ? ('0' + d) : d      
      let h = date.getHours()
      h = h < 10 ? ('0' + h) : h      
      let m = date.getMinutes()
      m = m < 10 ? ('0' + m) : m      
      let s = date.getSeconds()
      s = s < 10 ? ('0' + s) : s      
      return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s
   },
  formatTimeStamp (value) {
    var date = this.handleDate(value)
    return date.getTime()
  },
  phoneFun (phones) {
    var myreg = /^[1][2,3,4,5,6,7,8,9][0-9]{9}$/
    if (!myreg.test(phones)) {
      console.log('手机号格式不正确')
      return false
    } else {
      console.log('手机号格式正确')
      return true
    }
  },
  weekListByStr (str) {
    let arr = str.split(',')
    let week = []
    arr.forEach(element => {
      if (Number(element) === 1) {
        week.push({
          weekDay: 1,
          name: '星期一'
        })
      } else if (Number(element) === 2) {
        week.push({
          weekDay: 2,
          name: '星期二'
        })
      } else if (Number(element) === 3) {
        week.push({
          weekDay: 3,
          name: '星期三'
        })
      } else if (Number(element) === 4) {
        week.push({
          weekDay: 4,
          name: '星期四'
        })
      } else if (Number(element) === 5) {
        week.push({
          weekDay: 5,
          name: '星期五'
        })
      } else if (Number(element) === 6) {
        week.push({
          weekDay: 6,
          name: '星期六'
        })
      } else if (Number(element) === 7) {
        week.push({
          weekDay: 7,
          name: '星期日'
        })
      }
    })
    return week
  },
  weekArr () {
    return [{
      num: 1,
      name: '星期一'
    }, {
      num: 2,
      name: '星期二'
    }, {
      num: 3,
      name: '星期三'
    }, {
      num: 4,
      name: '星期四'
    }, {
      num: 5,
      name: '星期五'
    }, {
      num: 6,
      name: '星期六'
    }, {
      num: 7,
      name: '星期日'
    }]
  },
  weekToName (num) {
    switch (Number(num)) {
      case 1:
        return '星期一'
      case 2:
        return '星期二'
      case 3:
        return '星期三'
      case 4:
        return '星期四'
      case 5:
        return '星期五'
      case 6:
        return '星期六'
      case 7:
      return '星期日'
    }
  },
  dayWeek (num) {
    var now = new Date()
    var day = now.getDay()
    var weeks = new Array(7, 1, 2, 3, 4, 5, 6)
    return num === weeks[day]
  },
  ages (str) {  
    var r = str.match(/^(\d{1,4})(-|\/)(\d{1,2})\2(\d{1,2})$/)
    if (r === null) return false
    var d = new Date(r[1], r[3]-1, r[4])
    if (d.getFullYear()==r[1]&&(d.getMonth()+1)==r[3]&&d.getDate()==r[4]) {  
      var Y = new Date().getFullYear()
      return Y-r[1]
    }  
    return ''
  }  
}